import React, { useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";

export default function FundTransferListComponent({ data }) {
  const [filterText, setFilterText] = useState("");

  const getWalletDetails = (type) => {
    switch (type) {
      case 1:
        return `Staking Wallet`;
      case 2:
        return `Affiliate Wallet`;
      case 4:
        return `Royalty Wallet`;
      default:
        return "-NA-";
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Wallet",
      selector: (row) => getWalletDetails(row.walletType),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => `${row.amount} BTMETA`,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY h:mm:ss a"),
      sortable: true,
    },
  ];

  const filteredData = data.filter(item =>
    item.email.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div className="container">
      <h4 className="text-start ">Transfer List</h4>
      <input
        type="text"
        placeholder="Search by Email"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        style={{ marginBottom: "10px", padding: "5px", width: "100%" }}
      />
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover
        responsive
        noDataComponent={<p>No Record Found!</p>}
      />
    </div>
  );
}
