import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import StakingHistoryComponent from "./StakingHistoryComponent";
import { get_reward_update, getuserTransferFund } from "../../../common/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataTableViewTransaction from "./DataTableViewTransaction";
import DirectReferalTransaction from "./DirectReferalTransaction";
import Spinner from "react-bootstrap/Spinner";
import FundTransferListComponent from "../FundTranfer/FundTransferListComponent";

export default function TransactionComponent() {
  const { user, token } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("home"); // Default active tab
  const [loading, setLoading] = useState(false);
  const [fund, setFund] = useState([]);

  const get_user_transfer_fund = async () => {
    try {
      const data = await getuserTransferFund(user._id, token);

      setFund(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const get_user_record = async (type, value) => {
    try {
      setLoading(true); // Start loader
      const response = await get_reward_update(user._id, token, type, value);
      setData(response);
    } catch (error) {
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false); // Stop loader after data is fetched or error occurs
    }
  };

  useEffect(() => {
    // Fetch today's transaction data on initial render
    if (activeTab === "home") {
      get_user_record("today", 6);
    }
  }, [activeTab, user, token]); // Run when activeTab, user, or token changes

  const handleSelect = (key) => {
    setActiveTab(key);
    switch (key) {
      case "home":
        setData([]);
        get_user_record("today", 6);
        break;
      case "staking":
        setData([]);
        get_user_record("staking", 0);
        break;
      case "affiliate-transaction":
        setData([]);
        get_user_record("affiliate", 1);
        break;
      case "deposit":
        setData([]);
        get_user_record("deposit", 2);
        break;
      case "withdraw":
        setData([]);
        get_user_record("withdraw", 4);
        break;
      case "direct":
        setData([]);
        get_user_record("direct", 5);
        break;
      case "fundTransferList":
        setData([]);
        get_user_transfer_fund();
        break;
      default:
        break;
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <h2 className="text-white pb-4">My Transaction History</h2>
      <Tabs
        activeKey={activeTab}
        onSelect={handleSelect}
        id="fill-tab-example"
        className="mb-3 tab-transaction"
        fill
      >
        <Tab eventKey="home" title="Today's Transaction" className="tab-his">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="light" />{" "}
              {/* Show spinner */}
              <div>Loading...</div>
            </div>
          ) : (
            <DataTableViewTransaction data={data} title="Today Transactions" />
          )}
        </Tab>
        <Tab eventKey="staking" title="Staking">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="light" />
              <div>Loading...</div>
            </div>
          ) : (
            <DataTableViewTransaction
              data={data}
              title="Daily Reward History"
            />
          )}
        </Tab>
        <Tab eventKey="affiliate-transaction" title="Affiliate Transaction">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="light" />
              <div>Loading...</div>
            </div>
          ) : (
            <DataTableViewTransaction
              data={data}
              title="Affiliate Transaction"
            />
          )}
        </Tab>
        <Tab eventKey="deposit" title="Deposit">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="light" />
              <div>Loading...</div>
            </div>
          ) : (
            <DataTableViewTransaction data={data} title="Deposit History" />
          )}
        </Tab>
        <Tab eventKey="withdraw" title="Withdraw">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="light" />
              <div>Loading...</div>
            </div>
          ) : (
            <DataTableViewTransaction data={data} title="Withdraw History" />
          )}
        </Tab>
        <Tab eventKey="direct" title="Direct Referral">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="light" />
              <div>Loading...</div>
            </div>
          ) : (
            <DirectReferalTransaction data={data} title="Direct Referral" />
          )}
        </Tab>

        <Tab eventKey="fundTransferList" title="Fund Transfer">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="light" />
              <div>Loading...</div>
            </div>
          ) : (
            <FundTransferListComponent data={fund} />
          )}
        </Tab>
      </Tabs>
    </div>
  );
}
